import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Section, Container } from "../global"
import checklistenImg from "../../images/product/organizer_checklist.png"
import erstausstattungImg from "../../images/product/organizer_configurator.jpg"
import ratgeberImg from "../../images/product/organizer_ratgeber.png"
import erinnerungsfunktionImg from "../../images/product/organizer_alarm.png"
import personalisierbarImg from "../../images/product/organizer_personalize.png"
import aktionenImg from "../../images/product/organizer_discounts.png"

const FeatureLinks = () => {
  return (
    <StyledSection id="feature-links">
      <StyledContainer>
        <h2>Ihre Vorteile</h2>
        <LinksGrid>
          <Link to="/checklisten" style={linkStyles}>
            <img src={checklistenImg} />
            <LinkText>
              Checklisten für Einzug, Tierarzt, Versicherung, u.v.m.
            </LinkText>
          </Link>
          <Link to="/erstausstattung" style={linkStyles}>
            <img src={erstausstattungImg} />
            <LinkText>Konfigurator für Ihre Erstausstattung</LinkText>
          </Link>
          <Link to="/ratgeber" style={linkStyles}>
            <img src={ratgeberImg} />
            <LinkText>Ratgeber &amp; Experten-Tipps</LinkText>
          </Link>
          <Link to="/erinnerungsfunktion" style={linkStyles}>
            <img src={erinnerungsfunktionImg} />
            <LinkText>Erinnerungsfunktion</LinkText>
          </Link>
          <Link to="/personalisierbar" style={linkStyles}>
            <img src={personalisierbarImg} />
            <LinkText>
              Personalisierbar auf Ihren Welpen und Ihre Bedürfnisse
            </LinkText>
          </Link>
          <Link to="/aktionen" style={linkStyles}>
            <img src={aktionenImg} />
            <LinkText>Exklusive Aktionen und Rabatte</LinkText>
          </Link>
        </LinksGrid>
      </StyledContainer>
    </StyledSection>
  )
}

export default FeatureLinks

const StyledContainer = styled(Container)``

const StyledSection = styled(Section)`
  padding-top: 20px;
  padding-bottom: 30px;
`

const LinksGrid = styled.div`
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
`

const linkStyles = {
  position: "relative",
  flexBasis: "33%",
  color: "#3D5676",
  fontWeight: "900",
  marginBottom: "15px"
}

const LinkText = styled.div`
  width: 240px;
  padding: 5px 10px;
  position: absolute;
  bottom: 15px;
  background: rgba(255, 255, 255, 0.75);
  text-decoration: underline;
`
