import React, { Component } from "react"
import Scrollspy from "react-scrollspy"
import { Link } from "gatsby"
import { Nav, Brand, StyledContainer } from "./style"
import { SpanBold } from "../../global"

export default class Navigation extends Component {
  state = {
    hasScrolled: false
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  handleScroll = event => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 32) {
      this.setState({ hasScrolled: true })
    } else {
      this.setState({ hasScrolled: false })
    }
  }

  toggleMobileMenu = () => {
    this.setState(prevState => ({ mobileMenuOpen: !prevState.mobileMenuOpen }))
  }

  render() {
    return (
      <Nav {...this.props} scrolled={this.state.hasScrolled}>
        <StyledContainer>
          <Brand>
            <Scrollspy offset={-64} item={["top"]} currentClassName="active">
              <Link to="/">
                Welpen<SpanBold>Organizer</SpanBold>
              </Link>
            </Scrollspy>
          </Brand>
        </StyledContainer>
      </Nav>
    )
  }
}
