import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Container } from "../global"

const Footer = () => (
  <FooterWrapper id="footer">
    <FooterColumnContainer>
      <FooterColumn>
        <StyledLink to={"impressum"}>Impressum</StyledLink>
      </FooterColumn>
    </FooterColumnContainer>
    <Footnote>
      *Beim Welpen-Organizer handelt es sich momentan um einen Test und kein
      funktionsfähiges Produkt. Durch Klicken auf den Button „Jetzt kostenlos
      anmelden*“ zeigen Sie Interesse und tragen somit zur Entwicklung des
      Welpen-Organizers bei, um zukünftigen Welpenbesitzern zu helfen. Durch das
      Klicken auf den Button „Jetzt kostenlos anmelden*“ entstehen Ihnen
      keinerlei Nachteile oder Verpflichtungen, insbesondere werden Ihre
      persönlichen Daten nicht gespeichert.
    </Footnote>
  </FooterWrapper>
)

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.color.primary};
`

const FooterWrapper = styled.footer`
  background-color: white;
  margin: 60px 0 0;
  padding: 60px 0 80px 0;
  position: relative;
  background-color: ${props => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
  @media (max-width: ${props => props.theme.screen.md}) {
  }
`

const Footnote = styled(Container)`
  margin-top: 20px;
  ${props => props.theme.font_size.xxsmall};
  color: ${props => props.theme.color.black.lightest};
`
const FooterColumnContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 16px;
  justify-content: start;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
`
const FooterColumn = styled.div`
  span {
    font-size: 16px;
    font-family: ${props => props.theme.font.bold};
    color: ${props => props.theme.color.primary};
  }
  ul {
    list-style: none;
    margin: 16px 0;
    padding: 0;
    color: ${props => props.theme.color.black.regular};
    li {
      margin-bottom: 12px;
      font-family: ${props => props.theme.font.normal};
      font-size: 15px;
    }
  }
`

export default Footer
